import Navbar from "../../Component/LandingV2/Navbar";
import SubNavbar from "../../Component/LandingV2/SubNavbar";
import { useEffect, useState } from "react";
import "./NewStatergy.scss";
import Plot from "react-plotly.js";
import FilterSelect from "../../Component/StockSelection/FilterSelect";
import NormalisedGraphV2 from "./NormalisedGraphV2";
import BacktestingTableV2 from "./BacktestingTableV2";
import BacktestingGraphV2 from "./BacktestingGraphV2";
import HeatMap from "./HeatMap";
import GraphDataDummy from "../../data/stock-selection/Apr-2015.json";



export type PerformanceData = {
  Date: string[];
  Investment: number[];
};
export type NormalizedPrices = {
  Date: string[];
  [key: string]: number[] | string[];
};
export type FilterData = {
  Stocks: string[];
  "Backtesting Performance Our Stock": PerformanceData;
  "Backtesting Performance NIFTYBEES": PerformanceData;
  "Our Stocks Parameters"?: StockParameters;
  "NIFTYBEES Parameters"?: StockParameters;
  "Normalized Price Over Time STOCKS": NormalizedPrices;
  "Normalized Price Over Time INDICES": NormalizedPrices;
};
export type strategyGraphData = {
  "Filter 3 6STOCKS MaxSharpe": FilterData;
};
interface SubNavItem {
  name: string;
  href: string;
  dropdown?: SubNavItem[];
}

interface PlotData {
  graphData: {
    Date: string[];
    "Final Investment": number[];
  };
  NIFTYBEES: {
    Date: string[];
    "Final Investment": number[];
  };
}

export type StockParameters = {
  "Initial Capital": number;
  "Final Capital": number;
  "Annualized Returns": number;
  "Calmar Ratio": number;
  "Sharpe Ratio": number;
  "Sortino Ratio": number;
  "Max Drawdown": number;
  [key: string]: number;
};

type HistoricalData = {
  "NIFTY SMALLCAP 100": StockParameters;
  "NIFTY 100": StockParameters;
  "NIFTY TMI": StockParameters;
  NIFTYBEES: StockParameters;
  "Our Strategy": StockParameters;
  [key: string]: StockParameters;
};

const subNavData: SubNavItem[] = [
  // {
  //   name: "Strong Stocks",
  //   href: "/strong-stocks-home",
  //   dropdown: [
  //     {
  //       name: "Home",
  //       href: "/strong-stocks-home",
  //     },
  //     {
  //       name: "Stock Selection",
  //       href: "/stock-selection",
  //     },
  //   ],
  // },
  {
    name: "Nifty Strategy",
    href: "/nifty-statergy",
  },
  {
    name: "Stock C2C",
    href: "/stock-c2c",
  },
  {
    name: "Momentum Strategy",
    href: "/momentum-strategy",
  },
  {
    name: "Value Strategy",
    href: "/value-statergy",
  },
];

const getFutureMonths = (currentYear: number, selectedYear: string) => {
  const currentMonth = new Date().getMonth(); // 0 (Jan) to 11 (Dec)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (parseInt(selectedYear) === currentYear) {
    return months.slice(currentMonth + 1);
  }
  return [];
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const years = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
];

const NewStatergy = () => {
  const [graphData, setGraphData] = useState<strategyGraphData | null>(
    GraphDataDummy
  );
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [plotData, setPlotData] = useState<PlotData | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const currentYear = new Date().getFullYear();
  const getLastMonth = () => {
    const now = new Date();
    const lastMonthIndex = now.getMonth(); // getMonth() returns 0 for Jan, 1 for Feb, etc.
    return months[lastMonthIndex < 0 ? 11 : lastMonthIndex]; // If last month is negative, wrap around to December
  };

  const [monthValue, setMonthValue] = useState(getLastMonth());
  const [yearValue, setYearValue] = useState(currentYear.toString());
  const [historicalData, setHistoricalData] = useState<HistoricalData>();

  const todayDate = new Date().toISOString().split("T")[0];

  // const [graphData, setGraphData] = useState<GraphData | null>(GraphDataDummy);

  const stockOrder = {
    green: 1,
    blue: 2,
    yellow: 3,
    red: 4,
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/analysis-fabric-files/Momentum_Value_Strategy/${encodeURI(
            monthValue
          )}+${encodeURI(yearValue)}.json`
      );
      const data = await response.json();
      console.log(data);
      setGraphData(data);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/Stock-Selection/momentum_value_files/${todayDate}.csv`
      );
      const text = await response.text();
      const csvData = textToJSON(text);

      const sortedData = sortStocks(csvData);
      setData(sortedData);
      setFilteredData(sortedData); // Initialize with all data
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/backtest_results_daily/Momentum_Value_Strategy/table_data.json`
      );
      const data = await response.json();
      // console.log(data);
      setHistoricalData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const sortStocks = (stocks: any[]) => {
    return stocks.sort((a, b) => {
      const aClass = getStockClass(a);
      const bClass = getStockClass(b);
      return stockOrder[aClass] - stockOrder[bClass];
    });
  };

  const getStockClass = (item: any) => {
    if (
      item.Meets_ROCE_ROE_Condition === "True" &&
      item.Meets_Price_Ratio_Condition === "True" &&
      item.Final_Selected === "1"
    ) {
      return "green";
    } else if (
      item.Meets_ROCE_ROE_Condition === "True" &&
      item.Meets_Price_Ratio_Condition === "True"
    ) {
      return "blue";
    } else if (
      item.Meets_ROCE_ROE_Condition === "True" ||
      item.Meets_Price_Ratio_Condition === "True"
    ) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const fetchPlotData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/Stock-Selection/backtest_results_daily/Momentum_Value_Strategy/plots_data.json`
      );
      const data = await response.json();
      // console.log(data);
      setPlotData({
        graphData: data["Momentum_Value_Strategy"],
        NIFTYBEES: data["NIFTYBEES"],
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Convert CSV to JSON format
  // it should not return NaN values
  const textToJSON = (csv: string) => {
    const lines = csv.split("\n");
    const headers = lines[0].split(",");
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      const currentline = lines[i].split(",");
      if (currentline.length > 1) {
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }
    return result;
  };

  useEffect(() => {
    fetchData();
    fetchPlotData();
    fetchHistoricalData();
  }, [monthValue, yearValue]);

  // Sorting logic
  const handleSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (key === "Stock") {
        return direction === "ascending"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === "ascending"
          ? parseFloat(a[key]) - parseFloat(b[key])
          : parseFloat(b[key]) - parseFloat(a[key]);
      }
    });
    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  // Determine sort icon based on the current sort configuration
  const getSortIcon = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) {
      return ""; // No sort icon
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  // Filter rows based on the selected legend
  const handleFilter = (colorClass: string) => {
    const filtered = data.filter((item) => {
      if (colorClass === "green") {
        return (
          item.Meets_ROCE_ROE_Condition === "True" &&
          item.Meets_Price_Ratio_Condition === "True" &&
          item.Final_Selected === "1"
        );
      } else if (colorClass === "blue") {
        return (
          item.Meets_ROCE_ROE_Condition === "True" &&
          item.Meets_Price_Ratio_Condition === "True"
        );
      } else if (colorClass === "yellow") {
        return (
          item.Meets_ROCE_ROE_Condition === "True" ||
          item.Meets_Price_Ratio_Condition === "True"
        );
      } else {
        return true;
      }
    });
    setFilteredData(sortStocks(filtered));
  };

  function getYesterdayDate() {
    const date = new Date();
    date.setDate(date.getDate() - 1); // Set date to yesterday

    const day = date.getDate(); // Day of the month
    const year = date.getFullYear(); // Year

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[date.getMonth()]; // Get month name

    return `${day} ${month} ${year}`;
  }

  // console.log({ plotData, tableData, valueBasedData, data, filteredData });
  return (
    <>
      <Navbar />
      <SubNavbar navData={subNavData} />
      {/* create two tabs of momentum value statergy and value base statergy  */}
      <div style={{ padding: "2rem" }}>
        {/* Momenum based Strategy */}
        <div className="new-statergy-container">
          {/* Legend Section */}
          <div>
          <div className="color-legend">
            <div className="main-legend">
              <p onClick={() => handleFilter("green")}>
                <span className="legend-box green"></span> Max Sharpe Optimised
                Stocks
              </p>
              <p onClick={() => handleFilter("blue")}>
                <span className="legend-box blue"></span> Meets Price Ratio
                Condition Stocks
              </p>
              <p onClick={() => handleFilter("yellow")}>
                <span className="legend-box yellow"></span> Meets ROCE or ROE
                Condition Stocks
              </p>
              <p onClick={() => handleFilter("red")}>
                <span className="legend-box red"></span> Momentum Stocks
              </p>
            </div>
            <div className="update-line">Stocks Updated on {todayDate}</div>
            {/* <p onClick={() => setFilteredData(data)}>Show All</p> Button to reset filter */}
          </div>
          </div>
          <div className = "table-momentum-strategy">
          <table className="table">
            <thead>
              <tr>
                <th onClick={() => handleSort("Stock")}>
                  Stock {getSortIcon("Stock")}
                </th>
                <th onClick={() => handleSort("ROE_Average")}>
                  ROE Avg {getSortIcon("ROE_Average")}
                </th>
                <th onClick={() => handleSort("ROCE_Average")}>
                  ROCE Avg {getSortIcon("ROCE_Average")}
                </th>
                {/* <th>ROCE ROCE Condition</th>
                <th>Price Ratio Condition</th> */}
                <th onClick={() => handleSort("PS")}>PS {getSortIcon("PS")}</th>
                <th onClick={() => handleSort("PE")}>PE {getSortIcon("PE")}</th>
                <th onClick={() => handleSort("PB")}>PB {getSortIcon("PB")}</th>
                {/* <th onClick={() => handleSort("Final_Selected")}>
                  Final Selected {getSortIcon("Final_Selected")}
                </th> */}
                <th onClick={() => handleSort("Avg_Price_Rank")}>
                  Avg Price Rank {getSortIcon("Avg_Price_Rank")}
                </th>
                <th onClick={() => handleSort("5 Day Avg Volume (Cr)")}>
                  5 Day Avg Volume (Cr) {getSortIcon("5 Day Avg Volume (Cr)")}
                </th>
                <th>Company Name</th>
                <th>Sector</th>
                <th>Industry</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length !== 0 ? (
                filteredData.map((item, index) => {
                  // Conditional class logic
                  let rowClass = "";
                  if (
                    item.Meets_ROCE_ROE_Condition === "True" &&
                    item.Meets_Price_Ratio_Condition === "True" &&
                    item.Final_Selected === "1"
                  ) {
                    rowClass = "green-row";
                  } else if (
                    item.Meets_ROCE_ROE_Condition === "True" &&
                    item.Meets_Price_Ratio_Condition === "True"
                  ) {
                    rowClass = "blue-row";
                  } else if (
                    item.Meets_ROCE_ROE_Condition === "True" ||
                    item.Meets_Price_Ratio_Condition === "True"
                  ) {
                    rowClass = "yellow-row";
                  } else {
                    rowClass = "red-row";
                  }
                  return (
                    <tr key={index} className={rowClass}>
                      <td>{item.Stock}</td>
                      <td>{item.ROE_Average}</td>
                      <td>{item.ROCE_Average}</td>
                      {/* <td>{item.Meets_ROCE_ROCE_Condition}</td>
                    <td>{item.Meets_Price_Ratio_Condition}</td> */}
                      <td>{item.PS}</td>
                      <td>{item.PE}</td>
                      <td>{item.PB}</td>
                      {/* <td>{item.Final_Selected}</td> */}
                      <td>{item.Avg_Price_Rank}</td>
                      <td>
                        {/* if NAN value then keep the cell empty  */}
                        {parseFloat(item["5 Day Avg Volume (Cr)"]).toFixed(2)}
                      </td>
                      <td>{item["Company Name"]}</td>
                      <td>{item.Sector}</td>
                      <td>{item.Industry}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={14}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
        </div>
        <div className="info-line">
          *Our Strategy suggests to hold these stocks for next one year and then
          liquidate it
        </div>

        <div>
          <h1 style={{ textAlign: "center", margin: "1rem" }}>
            How Our Strategy Works?
          </h1>
          <div className="plot-table-container">
            <div className="chart-img">
              <h5 style={{ marginBottom: "1rem" }}>
                On the first of every month:
              </h5>
              <img className = "img-strategy"
                src="assets/images/momentum-value/momentum-chart.png"
                alt="Momentum Value Chart"
              />
            </div>
            <div>
              <h4 style={{ textAlign: "center", margin: "1rem" }}>
                Performance of strategy vs NiftyBees over last 10 years
              </h4>
              <div className="momentum-value-plot">
                {plotData && (
                  <Plot
                    data={[
                      {
                        x: plotData.graphData.Date,
                        y: plotData.graphData["Final Investment"],
                        type: "scatter",
                        mode: "lines",
                        name: "Momentum Strategy",
                        line: { color: "red" },
                      },
                      {
                        x: plotData["NIFTYBEES"].Date,
                        y: plotData["NIFTYBEES"]["Final Investment"],
                        type: "scatter",
                        mode: "lines",
                        name: "NIFTYBEES",
                        line: { color: "blue" },
                      },
                    ]}
                    layout={{
                      title: `Momentum Strategy vs NIFTYBEES`,
                      xaxis: {
                        title: "Date",
                      },
                      yaxis: {
                        title: "Final Investment",
                      },
                      legend: {
                        x: 0.5,
                        y: 1,
                        orientation: "v",
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="subtext">
          <h1>
            Let's Evaluate the Effectiveness of this trading strategy by running
            it against historical data of more than 10 years
          </h1>
        </div>
        <div className="portfolio-section">
          <h1>Portfolio Historical Performance Review</h1>
          <div className="text-image-split">
            <div className="historical-performance-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>NIFTY SMALLCAP 100</th>
                    <th>NIFTY 100</th>
                    <th>NIFTY TMI</th>
                    <th>NIFTYBEES</th>
                    <th>Our Strategy</th>
                  </tr>
                </thead>
                <tbody>
                  {historicalData &&
                    Object.keys(historicalData["NIFTY SMALLCAP 100"]).map(
                      (key) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            {historicalData?.["NIFTY SMALLCAP 100"][
                              key
                            ].toFixed(2)}
                          </td>
                          <td>
                            {historicalData?.["NIFTY 100"][key].toFixed(2)}
                          </td>
                          <td>
                            {historicalData?.["NIFTY TOTAL MARKET"][
                              key
                            ].toFixed(2)}
                          </td>
                          <td>
                            {historicalData?.["NIFTYBEES"][key].toFixed(2)}
                          </td>
                          <td>
                            {historicalData?.["Momentum_Value_Strategy"][
                              key
                            ].toFixed(2)}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <div className="info-line">
                *The above table shows performance from 1 July, 2014 to{" "}
                {getYesterdayDate()}
              </div>
            </div>
          </div>
        </div>
        <div className="heatmap">
          <div className="title">
            Months when Momentum Strategy outperformed NIFTYBEES.NS
          </div>
          <div
            style={{
              width: "70%",
              margin: "0 auto",
            }}
          >
            <HeatMap />
          </div>
        </div>
        <div className="analysis">
          <div className="title">
            Explore Historical Performance on Your Terms
          </div>
          <div className="analysis-filters">
            <FilterSelect
              options={months}
              value={monthValue}
              onChange={setMonthValue}
              placeholder="Select Month"
              disabledOptions={getFutureMonths(currentYear, yearValue)}
            />

            <FilterSelect
              options={years}
              value={yearValue}
              onChange={setYearValue}
              placeholder="Select Year"
            />
          </div>
        </div>
        <div className="line-graph">
          <div className="month-title">
            {monthValue} {yearValue} Stocks along with their Normalized Price
            Over Time
          </div>

          <div className="normalised-graph">
            <NormalisedGraphV2 statergyGraphData={graphData} />
            <div className="info-line">
              *The chart shows the performance of selected stocks over 5 years
              before and  upto 1 year after selection.
            </div>
          </div>
        </div>
        <div className="backtesting">
          <div className="forwardTesting-title">
            Forward Testing Performance {monthValue} {yearValue} stocks
          </div>
          <div className="table-graph-forward-testing">
            <div className="data-table-forward-testing">
              <BacktestingTableV2 statergyGraphData={graphData} />
            </div>
            <div className="graph-forward-testing">
              <BacktestingGraphV2 statergyGraphData={graphData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewStatergy;
