import React, { useEffect, useState } from "react";
import "./StockC2C.scss";
import Stockc2cDummy from "../../data/stockc2c/final-result-df_post_market.json";
import Stockc2cFilterDummy from "../../data/stockc2c/final-result-df-PreMarket.json";
import Navbar from "../../Component/LandingV2/Navbar";
import SubNavbar from "../../Component/LandingV2/SubNavbar";
import axios from "axios";
import SectorResultsinc2c from './StockResultsTable';
interface StockC2CData {
  Symbol: string;
  Date: string;
  "Short probability": number;
  "Long probability": number;
  "Predicted Value": number;
}

interface SubNavItem {
  name: string;
  href: string;
  dropdown?: SubNavItem[];
}

const subNavData: SubNavItem[] = [
  // {
  //   name: "Strong Stocks",
  //   href: "/strong-stocks-home",
  //   dropdown: [
  //     {
  //       name: "Home",
  //       href: "/strong-stocks-home",
  //     },
  //     {
  //       name: "Stock Selection",
  //       href: "/stock-selection",
  //     },
  //   ],
  // },
  {
    name: "Nifty Strategy",
    href: "/nifty-statergy",
  },
  {
    name: "Stock C2C",
    href: "/stock-c2c",
  },
  {
    name: "Momentum Strategy",
    href: "/momentum-strategy",
  },
  {
    name: "Value Strategy",
    href:"/value-statergy"
  }
];

const StockC2C = () => {
  const [data, setData] = useState<StockC2CData[]>(
    Stockc2cDummy as StockC2CData[]
  );
  const [filterData, setFilterData] = useState<StockC2CData[]>(
    Stockc2cFilterDummy as StockC2CData[]
  );
  const [sortConfig, setSortConfig] = useState<{
    key: keyof StockC2CData;
    direction: string;
  }>({
    key: "Symbol",
    direction: "ascending",
  });
  const [sortFilterConfig, setSortFilterConfig] = useState<{
    key: keyof StockC2CData;
    direction: string;
  }>({
    key: "Symbol",
    direction: "ascending",
  });
  const [visibleTable, setVisibleTable] = useState<"none" | "main" | "filter">(
    "none"
  );

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://stockc2c.blob.core.windows.net/final-result-df/final-result_pre_market.json"
      );
      if (response.data && Array.isArray(response.data)) {
        const filteredData = response.data.filter(item => 
          item !== null && typeof item === 'object' && Object.keys(item).every(key => item[key as keyof StockC2CData] !== null)
        );
        
        const sortedData = filteredData.sort((a, b) => {
          return a?.Symbol?.localeCompare(b?.Symbol); // Sort alphabetically by Symbol
        });
        setData(sortedData);
      } else {
        console.error("Unexpected data format");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchFilterData = async () => {
    try {
      const response = await axios.get(
        "https://stockc2cfilter1.blob.core.windows.net/filter-1-confidence/final-result-df-PreMarket.json"
      );
      if (response.data && Array.isArray(response.data)) {
        const filteredData = response.data.filter(item => 
          item !== null && typeof item === 'object' && Object.keys(item).every(key => item[key as keyof StockC2CData] !== null)
        );

        const sortedData = filteredData.sort((a, b) => {
          return a?.Symbol?.localeCompare(b?.Symbol); // Sort alphabetically by Symbol
        });
        setFilterData(sortedData);
      } else {
        console.error("Unexpected data format");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFilterData();
  }, []);

  const sortData = (
    key: keyof StockC2CData,
    dataToSort: StockC2CData[],
    setDataFn: React.Dispatch<React.SetStateAction<StockC2CData[]>>,
    sortConfig: { key: keyof StockC2CData; direction: string },
    setSortConfigFn: React.Dispatch<
      React.SetStateAction<{ key: keyof StockC2CData; direction: string }>
    >
  ) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setDataFn(sortedData);
    setSortConfigFn({ key, direction });
  };

  const sortFilterData = (
    key: keyof StockC2CData,
    dataToSort: StockC2CData[],
    setDataFn: React.Dispatch<React.SetStateAction<StockC2CData[]>>,
    sortConfig: { key: keyof StockC2CData; direction: string },
    setSortConfigFn: React.Dispatch<
      React.SetStateAction<{ key: keyof StockC2CData; direction: string }>
    >
  ) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setDataFn(sortedData);
    setSortConfigFn({ key, direction });
  };

  const toggleTableVisibility = (table: "main" | "filter") => {
    setVisibleTable((prev) => (prev === table ? "none" : table));
  };

  return (
    <>
      <Navbar />
      <SubNavbar navData={subNavData} />
      <div className="stock-c2c-section">
        <div className="stock-c2c-both-table">
        <div className="stock-c2c">
          <div className="heading">
            <div className="title">
              <h2>AlgoFabric Stocks C2C</h2>
              <p>AlgoFabric Stocks C2C Values for {data.length > 0 ? data[0]?.Date : ""}</p>
            </div>
            <div
              onClick={() => toggleTableVisibility("main")}
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                alignItems: "flex-end",
              }}
            >
              {visibleTable === "main" ? (
                <img src="assets/images/nifty/close.png" alt="open" />
              ) : (
                <img src="assets/images/nifty/Open.png" alt="close" />
              )}
            </div>
          </div>
          
          {visibleTable === "main" && (
            <div className="table-stockc2c">
              <table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>
                      Short Probability
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-funnel"
                          viewBox="0 0 16 16"
                          onClick={() =>
                            sortData(
                              "Short probability",
                              data,
                              setData,
                              sortConfig,
                              setSortConfig
                            )
                          }
                        >
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                        </svg>
                      </span>
                    </th>
                    <th>
                      Long Probability
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-funnel"
                          viewBox="0 0 16 16"
                          onClick={() =>
                            sortData(
                              "Long probability",
                              data,
                              setData,
                              sortConfig,
                              setSortConfig
                            )
                          }
                        >
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                        </svg>
                      </span>
                    </th>
                    <th>Predicted Value</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          item?.["Predicted Value"] === 1 ? "#D5F3C8" : "#F8D7DA",
                      }}
                    >
                      <td>{item?.Symbol ?? "-"}</td>
                      <td>{item?.["Short probability"]?.toFixed(2) ?? "-"}</td>
                      <td>{item?.["Long probability"]?.toFixed(2) ?? "-"}</td>
                      <td>{item?.["Predicted Value"] ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="stock-c2c">
          <div className="heading">
            <div className="title">
              <h2>Steady Performers C2C</h2>
              <p>
                Steady Performers C2C Values for{" "}
                {filterData.length > 0 ? filterData[0]?.Date : ""}
              </p>
            </div>
            <div
              onClick={() => toggleTableVisibility("filter")}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            >
              {visibleTable === "filter" ? (
                <img src="assets/images/nifty/close.png" alt="open" />
              ) : (
                <img src="assets/images/nifty/Open.png" alt="close" />
              )}
            </div>
          </div>
          {visibleTable === "filter" && (
            <div className="table-stockc2c">
              <table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>
                      Short Probability
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-funnel"
                          viewBox="0 0 16 16"
                          onClick={() =>
                            sortFilterData(
                              "Short probability",
                              filterData,
                              setFilterData,
                              sortFilterConfig,
                              setSortFilterConfig
                            )
                          }
                        >
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                        </svg>
                      </span>
                    </th>
                    <th>
                      Long Probability
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-funnel"
                          viewBox="0 0 16 16"
                          onClick={() =>
                            sortFilterData(
                              "Long probability",
                              filterData,
                              setFilterData,
                              sortFilterConfig,
                              setSortFilterConfig
                            )
                          }
                        >
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                        </svg>
                      </span>
                    </th>
                    <th>Predicted Value</th>
                  </tr>
                </thead>
                <tbody>
                  {filterData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          item?.["Predicted Value"] === 1 ? "#D5F3C8" : "#F8D7DA",
                      }}
                    >
                      <td>{item?.Symbol ?? "-"}</td>
                      <td>{item?.["Short probability"]?.toFixed(2) ?? "-"}</td>
                      <td>{item?.["Long probability"]?.toFixed(2) ?? "-"}</td>
                      <td>{item?.["Predicted Value"] ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className="sectorList">
    <SectorResultsinc2c></SectorResultsinc2c>
  </div>
      </div>
    </>
  );
};

export default StockC2C;
