import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";

import Navbar from "../Component/LandingV2/Navbar";
import MiniFooter from "../Component/LandingV2/MiniFooter";
import PriceCard from "../Component/Trending/PriceCard";
import TrendingEventCard from "../Component/Trending/TrendingEventCard";
import TickerFilter from "../Component/Trending/TickerFilter";

import "./TrendingPage.scss";

import { AlertData, AlertsJson, QuotesJson } from "../types/alerts.json";
import { Link } from "react-router-dom";
import { blue } from "@mui/material/colors";

const highlightBoxEnum = {
  PriceChange: 0,
  OpenPrices: 1,
  LastTradedPrice: 2,
  StockVolume: 3,
  MomentumChange: 4,
  AverageTrueRange: 5,
  AverageDirectionalIndex: 6,
  VolumeWeightedAveragePrice: 7,
  HotEvents: 8,
  Quotes: 9,
  NewsCount: 10,
  NewsStories: 11,
  FilterTicker: 12,
};
// useState
const infoBoxText = {
  [highlightBoxEnum.PriceChange]:
    " It indicates the percentage increase 📈 or decrease 📉 in the stock price relative to the previous day's closing price. 📊",
  [highlightBoxEnum.OpenPrices]:
    "New Value displayed in green indicates highest ⬆️ opening price over the last 15days with corresponding second highest Old value similarly value in red indicates lowest ⬇️ opening price over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.LastTradedPrice]:
    "New Value displayed in green indicates that last traded price is highest ⬆️ as compared to day's close over the last 15 days with corresponding second highest Old value. Similarly value in red indicates that the price is lowest ⬇️ over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.HotEvents]:
    "Provides a summary for each company’s most significant events. 📚",
  [highlightBoxEnum.NewsCount]:
    "This tab provides insights into a company by showcasing the number of news articles published in the last 24 hours and its weekly news average. 🗞️",
  [highlightBoxEnum.NewsStories]: `The "Top Stories" section highlights significant news where companies are prominently featured. Articles qualify as a top story if the corresponding company is mentioned or referenced in more than five sentences.`,
  [highlightBoxEnum.FilterTicker]:
    "Select a Ticker name of your choice to receive filtered results 🔎",
  [highlightBoxEnum.StockVolume]:
    "Stock volume alerts are based on minute-by-minute comparisons with the last 7 days. A high volume alert is triggered if the current volume exceeds the 7-day high for that exact minute, and vice versa for low volume.",
  [highlightBoxEnum.MomentumChange]:
    "Momentum-based alerts rely on three indicators: RSI, MFI, and Williams %R. If all three indicators simultaneously show the stock as oversold or overbought, an alert is generated to signal potential price movement in the opposite direction.",
  [highlightBoxEnum.AverageDirectionalIndex]:
    "ADX (Average Directional Index) measures trend strength. If ADX exceeds 40, it indicates a strong trend; below 15, it signals a weak trend, based on a 14-day comparison.",
  [highlightBoxEnum.AverageTrueRange]:
    "ATR (Average True Range) measures volatility. If the current ATR exceeds the 14-day max, a high volatility alert is triggered; if below the 14-day min, a low volatility alert is generated.",
  [highlightBoxEnum.VolumeWeightedAveragePrice]:
    "VWAP alerts are based on a 14-day comparison. If the current day's VWAP exceeds the 14-day maximum, an uptrend alert is generated, and if it's below the 14-day minimum, a downtrend alert is triggered.",
  [highlightBoxEnum.Quotes]:
    "This section provides a summary of the company's stock price, market cap, and trading volume.",
};

const Trending = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.ticker) {
      setTickerFilter(location.state.ticker);
    }
  }, [location.state]);

  const [vwapSortOrder, setVwapSortOrder] = useState("asc");
  const [vwapValueSortOrder, setVwapValueSortOrder] = useState("asc");
  const [activeVwapSortType, setActiveVwapSortType] = useState("ticker");

  // -----------
  const [adxSortOrder, setAdxSortOrder] = useState("asc"); // For sorting by ticker
  const [adxValueSortOrder, setAdxValueSortOrder] = useState("asc"); // For sorting by ADX value
  const [activeAdxSortType, setActiveAdxSortType] = useState("ticker"); // Track the active sort type

  // -----------

  const [atrSortOrder, setAtrSortOrder] = useState("asc"); // For sorting by ticker
  const [atrValueSortOrder, setAtrValueSortOrder] = useState("asc"); // For sorting by ATR value
  const [activeAtrSortType, setActiveAtrSortType] = useState("ticker"); // Track the active sort type

  // -----------
  const [volumeSortOrder, setVolumeSortOrder] = useState("asc"); // For sorting by ticker
  const [volumeValueSortOrder, setVolumeValueSortOrder] = useState("asc"); // For sorting by volume value
  const [activeVolumeSortType, setActiveVolumeSortType] = useState("ticker"); // Track the active sort type

  // -----------
  const [adSortOrder, setAdSortOrder] = useState("asc"); // For sorting by ticker
  const [adValueSortOrder, setAdValueSortOrder] = useState("asc"); // For sorting by volume value
  const [activeAdSortType, setActivAdSortType] = useState("ticker"); // Track the active sort type

  // -----------
  const [dpoSortOrder, setDpoSortOrder] = useState("asc"); // For sorting by ticker
  const [dpoValueSortOrder, setDpoValueSortOrder] = useState("asc"); // For sorting by DPO value
  const [activeDpoSortType, setActiveDpoSortType] = useState("ticker"); // Track the active sort type
  // -----------

  const [stockVsIndexTickerSortOrder, setStockVsIndexTickerSortOrder] =
    useState("asc"); // Sorting by ticker
  const [stockVsIndexStockPctSortOrder, setStockVsIndexStockPctSortOrder] =
    useState("asc"); // Sorting by stock percentage
  const [stockVsIndexIndexPctSortOrder, setStockVsIndexIndexPctSortOrder] =
    useState("asc"); // Sorting by index percentage
  const [activeStockVsIndexSortType, setActiveStockVsIndexSortType] =
    useState("ticker"); // Track active sort type

  // -----------
  const [closePriceSortOrder, setClosePriceSortOrder] = useState("asc"); // For ticker sorting
  const [closeValueSortOrder, setCloseValueSortOrder] = useState("asc"); // For new value sorting
  const [oldCloseValueSortOrder, setOldCloseValueSortOrder] = useState("asc"); // For old value sorting
  const [activeCloseSortType, setActiveCloseSortType] = useState("ticker"); // Track which column is currently sorted

  // -----------
  const [openPriceSortOrder, setOpenPriceSortOrder] = useState("asc");
  const [openValueSortOrder, setOpenValueSortOrder] = useState("asc");
  const [oldValueSortOrder, setOldValueSortOrder] = useState("asc");
  const [activeSortType, setActiveSortType] = useState("ticker");

  // -----------
  const [momentumSortOrder, setMomentumSortOrder] = useState("asc");
  const [momentumValueSortOrder, setMomentumValueSortOrder] = useState("asc");

  // const [priceChangeSortOrder, setPriceChangeSortOrder] = useState('asc');
  const [priceChangeTickerSortOrder, setPriceChangeTickerSortOrder] = useState<
    "asc" | "desc"
  >("asc");
  const [priceChangeValueSortOrder, setPriceChangeValueSortOrder] = useState<
    "asc" | "desc" | null
  >(null);
  const [quotes, setQuotes] = useState<QuotesJson>([]);
  const [data, setData] = useState<AlertData | null>(null);
  const [tickerFilter, setTickerFilter] = useState<string | null>(null);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [highlightBox, sethighlightBox] = useState(
    highlightBoxEnum.PriceChange
  );
  // const cardRef = useRef<HTMLDivElement>(null);
  const infoBoxRefList = useRef<(HTMLDivElement | null)[]>([]);

  const setInfoBoxRef = (index: number) => (ref: HTMLDivElement | null) => {
    infoBoxRefList.current[index] = ref;
  };

  const toggleInfoBox = () => {
    setShowInfoBox((prev) => !prev); // Show the info-box
    sethighlightBox(highlightBoxEnum.PriceChange); // Reset the highlight box
  };

  useEffect(() => {
    if (showInfoBox && infoBoxRefList.current) {
      const cardRef = infoBoxRefList.current[highlightBox];
      if (!cardRef) {
        console.log("Could not get bounding ref");
        return;
      }
      const cardRect = cardRef.getBoundingClientRect();
      const infoBox = document.getElementById("infoBox");
      if (infoBox) {
        const infoBoxWidth = infoBox.offsetWidth;
        const viewportWidth = window.innerWidth;
        let leftPosition = cardRect.right + window.scrollX + 20;
        let topPosition = cardRect.top + window.scrollY;
        if (viewportWidth < 400) {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40;
          }
          infoBox.style.width = "135px";
          infoBox.style.fontSize = "12px";
          topPosition = cardRect.bottom + window.scrollY + 5;
        } else if (viewportWidth < 768) {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40;
          }
          infoBox.style.width = "135px";
          infoBox.style.fontSize = "12px";
          topPosition = cardRect.bottom + window.scrollY + 5;
        } else {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = cardRect.left - infoBoxWidth - 5;
          }
          infoBox.style.width = "";
          infoBox.style.fontSize = "";
        }
        infoBox.style.top = `${topPosition}px`;
        infoBox.style.left = `${leftPosition}px`;
        infoBox.style.zIndex = "25";
      }
    }
  }, [showInfoBox, highlightBox]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_COMPANY_DOC || "") + "/Alerts/alerts.json"
      );
      // console.log("--------------------------------", await response.json());
      // const data: AlertsJson = await response.json();
      const data: AlertsJson = await response.json();

      const quotes_response = await fetch(
        (process.env.REACT_APP_COMPANY_DOC || "") + "/Alerts/quotes_alerts.json"
      );
      const quotesData: QuotesJson = await quotes_response.json();
      setQuotes(quotesData);

      const latestDate = Object.keys(data).sort().reverse()[0];

      setData({ ...data[latestDate], date: latestDate });
    } catch (err) {
      console.error(err);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     // Use the imported JSON data directly
  //     const data: AlertsJson = response;
  //     console.log("---------------data-----------------", data);

  //     // Find latest date
  //     const latestDate = Object.keys(data).sort().reverse()[0];

  //     setData({ ...data[latestDate], date: latestDate });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);

  // Loading state
  if (!data) {
    return (
      <>
        <Navbar />
        <div className="loading">Loading...</div>
      </>
    );
  }

  // Create list of open highest difference
  // List of highest
  const combinedOpenValues = Object.keys(
    data.price_change_alerts.alerts_open_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_open_highest[key],
      minmax: data.price_change_alerts.alerts_open_highest[key].max_open || 0,
    };
  });
  // Concat lowest
  combinedOpenValues.push(
    ...Object.keys(data.price_change_alerts.alerts_open_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_open_lowest[key],
        minmax: data.price_change_alerts.alerts_open_lowest[key].min_open || 0,
      };
    })
  );

  // Sort by difference between live day and minmax
  combinedOpenValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_open - a.minmax) -
        Math.abs(b.live_day_open - b.minmax)
      );
    })
    .reverse();

  // console.log(combinedOpenValues);

  // Create list of close highest difference
  // List of highest
  const combinedCloseValues = Object.keys(
    data.price_change_alerts.alerts_close_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_close_highest[key],
      minmax: data.price_change_alerts.alerts_close_highest[key].max_close || 0,
    };
  });
  // Concat lowest
  combinedCloseValues.push(
    ...Object.keys(data.price_change_alerts.alerts_close_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_close_lowest[key],
        minmax:
          data.price_change_alerts.alerts_close_lowest[key].min_close || 0,
      };
    })
  );

  const combinedDPO = Object.keys(data.dpo_alert?.Downward_cycle || {}).map(
    (key) => {
      return {
        DPO_TYPE: "down",
        ticker: key,
        ...data.dpo_alert?.Downward_cycle[key],
        minmax: data.dpo_alert?.Downward_cycle[key].lower_limit || 0, // Correct property
      };
    }
  );

  // Concat lowest (Upward cycle)
  combinedDPO.push(
    ...Object.keys(data.dpo_alert?.Upward_cycle || {}).map((key) => {
      return {
        DPO_TYPE: "up",
        ticker: key,
        ...data.dpo_alert?.Upward_cycle[key],
        minmax: data.dpo_alert?.Upward_cycle[key].upper_limit || 0, // Correct property
      };
    })
  );

  console.log("Accumulation Data:", data.AD_alert?.Accumulation);
  console.log("Distribution Data:", data.AD_alert?.Distribution);

  const combinedAD = Object.keys(data.AD_alert?.Accumulation || {}).map(
    (key) => {
      return {
        AD_TYPE: "accumulation",
        ticker: key,
        ...data.AD_alert?.Accumulation[key],
        minmax: data.AD_alert?.Accumulation[key].Max_AD || 0, // Correct property (Max_AD or Min_AD)
      };
    }
  );

  console.log("----------AD----", data.AD_alert?.Distribution); // Check if the data is present

  // Concat Distribution data
  combinedAD.push(
    ...Object.keys(data.AD_alert?.Distribution || {}).map((key) => {
      return {
        AD_TYPE: "distribution",
        ticker: key,
        ...data.AD_alert?.Distribution[key],
        minmax: data.AD_alert?.Distribution[key].Min_AD || 0, // Correct property (Max_AD or Min_AD)
      };
    })
  );

  const combined_Adx_values = data.ADX_change
    ? Object.keys(data.ADX_change.strong_trend).map((key) => {
        return {
          ticker: key,
          trendType: "strong trend", // Add the trend type
          ...data.ADX_change!.strong_trend[key],
        };
      })
    : [];

  // Concat weak trend stocks if ADX_change is available
  if (data.ADX_change) {
    combined_Adx_values.push(
      ...Object.keys(data.ADX_change.weak_trend).map((key) => {
        return {
          ticker: key,
          trendType: "weak trend", // Add the trend type
          ...data.ADX_change!.weak_trend[key],
        };
      })
    );
  }

  // ATR_change
  const combined_Atr_values = data.ATR_change?.high_volatility
    ? Object.keys(data.ATR_change.high_volatility).map((key) => ({
        ticker: key,
        volatilityType: "high_volatility",
        ...data.ATR_change!.high_volatility[key],
      }))
    : [];

  if (data.ATR_change?.low_volatility) {
    combined_Atr_values.push(
      ...Object.keys(data.ATR_change.low_volatility).map((key) => ({
        ticker: key,
        volatilityType: "low_volatility",
        ...data.ATR_change!.low_volatility[key],
      }))
    );
  }

  // StockVolume_change
  const combined_volume_change = data.StockVolume_change?.Stock_volume_high
    ? Object.keys(data.StockVolume_change.Stock_volume_high).map((key) => {
        return {
          ticker: key,
          volume_type: "high_volume",
          ...data.StockVolume_change!.Stock_volume_high[key],
        };
      })
    : [];

  if (data.StockVolume_change?.Stock_volume_low) {
    combined_volume_change.push(
      ...Object.keys(data.StockVolume_change.Stock_volume_low).map((key) => {
        return {
          ticker: key,
          volume_type: "low_volume",
          ...data.StockVolume_change!.Stock_volume_low[key],
        };
      })
    );
  }

  // VWAP_change
  const combined_Vwap = data.VWAP_change?.Uptrend
    ? Object.keys(data.VWAP_change.Uptrend).map((key) => {
        return {
          ticker: key,
          trend_type: "uptrend",
          ...data.VWAP_change!.Uptrend[key],
        };
      })
    : [];

  if (data.VWAP_change?.Downtrend) {
    combined_Vwap.push(
      ...Object.keys(data.VWAP_change.Downtrend).map((key) => {
        return {
          ticker: key,
          trend_type: "downtrend",
          ...data.VWAP_change!.Downtrend[key],
        };
      })
    );
  }

  // Momentum_change
  const combined_momentum_change = data.Momentum_change?.Overbought
    ? Object.keys(data.Momentum_change.Overbought).map((key) => {
        return {
          ticker: key,
          bought_type: "overbought",
          ...data.Momentum_change!.Overbought[key],
        };
      })
    : [];

  if (data.Momentum_change?.Oversold) {
    combined_momentum_change.push(
      ...Object.keys(data.Momentum_change.Oversold).map((key) => {
        return {
          ticker: key,
          bought_type: "oversold",
          ...data.Momentum_change!.Oversold[key],
        };
      })
    );
  }

  // Sort by difference
  combinedCloseValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_close - a.minmax) -
        Math.abs(b.live_day_close - b.minmax)
      );
    })
    .reverse();

  // List of all unique tickers for filter
  // Sort alphabetically
  const tickerList = [
    ...new Set([
      ...Object.keys(
        data.price_change_alerts?.top_n_price_change?.stock_symbol || {}
      ),
      ...Object.values(data.price_change_alerts?.alerts_open_highest || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts?.alerts_open_lowest || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(
        data.price_change_alerts?.alerts_close_highest || []
      ).map((ele) => ele.ticker_symbol),
      ...Object.values(data.price_change_alerts?.alerts_close_lowest || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.ADX_change?.strong_trend || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.ADX_change?.weak_trend || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.ATR_change?.high_volatility || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.ATR_change?.low_volatility || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.AD_alert?.Accumulation || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.AD_alert?.Distribution || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.Momentum_change?.Overbought || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.Momentum_change?.Oversold || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.StockVolume_change?.Stock_volume_high || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.StockVolume_change?.Stock_volume_low || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.VWAP_change?.Downtrend || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.VWAP_change?.Uptrend || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.dpo_alert?.Downward_cycle || []).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.dpo_alert?.Upward_cycle || []).map(
        (ele) => ele.ticker_symbol
      ),
    ]),
  ].sort();

  const sortedAtrValues = [...combined_Atr_values].sort((a, b) => {
    if (activeAtrSortType === "ticker") {
      return atrSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeAtrSortType === "value") {
      return atrValueSortOrder === "asc"
        ? a.Last_ATR - b.Last_ATR
        : b.Last_ATR - a.Last_ATR;
    }
    return 0; // Default case
  });

  const toggleAtrSort = () => {
    setAtrSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveAtrSortType("ticker"); // Set active sort type to ticker
    setAtrValueSortOrder("asc"); // Reset value sort order
  };

  const toggleAtrValueSort = () => {
    setAtrValueSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveAtrSortType("value"); // Set active sort type to ATR value
    setAtrSortOrder("asc"); // Reset ticker sort order
  };

  const sortedAdxValues = [...combined_Adx_values].sort((a, b) => {
    if (activeAdxSortType === "ticker") {
      return adxSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeAdxSortType === "value") {
      return adxValueSortOrder === "asc" ? a.ADX - b.ADX : b.ADX - a.ADX;
    }
    return 0; // Default case
  });

  const toggleAdxSort = () => {
    setAdxSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveAdxSortType("ticker"); // Set active sort type to ticker
    setAdxValueSortOrder("asc"); // Reset value sort order
  };

  const toggleAdxValueSort = () => {
    setAdxValueSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveAdxSortType("value"); // Set active sort type to ADX value
    setAdxSortOrder("asc"); // Reset ticker sort order
  };

  const sortedVwapValues = [...combined_Vwap].sort((a, b) => {
    if (activeVwapSortType === "ticker") {
      return vwapSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeVwapSortType === "value") {
      const aVwap = a.current_vwap; // Already a number
      const bVwap = b.current_vwap; // Already a number

      // Ensure that aVwap and bVwap are valid numbers before performing arithmetic
      if (isNaN(aVwap) || isNaN(bVwap)) {
        // Handle NaN values as needed, for example, consider them equal
        return 0;
      }

      return vwapValueSortOrder === "asc" ? aVwap - bVwap : bVwap - aVwap;
    }
    return 0; // Default case
  });

  const toggleVwapSort = () => {
    setVwapSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveVwapSortType("ticker"); // Set active sort type to ticker
    setVwapValueSortOrder("asc"); // Reset value sort order
  };

  const toggleVwapValueSort = () => {
    setVwapValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveVwapSortType("value"); // Set active sort type to VWAP value
    setVwapSortOrder("asc"); // Reset ticker sort order
  };

  const sortedVolumeValues = [...combined_volume_change].sort((a, b) => {
    if (activeVolumeSortType === "ticker") {
      return volumeSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeVolumeSortType === "volumeValue") {
      return volumeValueSortOrder === "asc"
        ? a.Max_volume - b.Max_volume
        : b.Max_volume - a.Max_volume;
    }
    return 0; // Default case
  });

  const toggleVolumeSort = () => {
    setVolumeSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveVolumeSortType("ticker"); // Set active sort type to ticker
    setVolumeValueSortOrder("asc"); // Reset volume value sort
  };

  const toggleVolumeValueSort = () => {
    setVolumeValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveVolumeSortType("volumeValue"); // Set active sort type to volume value
    setVolumeSortOrder("asc"); // Reset ticker sort
  };

  const sortedAD = [...combinedAD].sort((a, b) => {
    if (activeAdSortType === "ticker") {
      // Ticker comparison returns a number (localeCompare is fine here)
      return adSortOrder === "asc"
        ? (a.ticker_symbol || "").localeCompare(b.ticker_symbol || "")
        : (b.ticker_symbol || "").localeCompare(a.ticker_symbol || "");
    } else if (activeAdSortType === "value") {
      // Ensure the value comparison returns a number
      const aValue = a["Current A/D"] || 0; // Default to 0 if undefined
      const bValue = b["Current A/D"] || 0; // Default to 0 if undefined
      return adValueSortOrder === "asc" ? aValue - bValue : bValue - aValue;
    }
    return 0; // Default case (no sorting)
  });

  // Toggle sorting by ticker
  const toggleAdTickerSort = () => {
    setAdSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActivAdSortType("ticker"); // Set active sort type to ticker
  };

  // Toggle sorting by value
  const toggleAdValueSort = () => {
    setAdValueSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActivAdSortType("value"); // Set active sort type to value
  };

  const sortedDPO = [...combinedDPO].sort((a, b) => {
    if (activeDpoSortType === "ticker") {
      return dpoSortOrder === "asc"
        ? (a.ticker_symbol || "").localeCompare(b.ticker_symbol || "")
        : (b.ticker_symbol || "").localeCompare(a.ticker_symbol || "");
    } else if (activeDpoSortType === "value") {
      const aValue = a.Current_dpo || 0; // Default to 0 if undefined
      const bValue = b.Current_dpo || 0; // Default to 0 if undefined
      return dpoValueSortOrder === "asc" ? aValue - bValue : bValue - aValue;
    }
    return 0; // Default case
  });

  // Toggle sorting by ticker
  const toggleDpoTickerSort = () => {
    setDpoSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveDpoSortType("ticker"); // Set active sort type to ticker
  };

  // Toggle sorting by value
  const toggleDpoValueSort = () => {
    setDpoValueSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveDpoSortType("value"); // Set active sort type to value
  };

  const sortedStockVsIndexData = [...Object.keys(data.stock_vs_index || {})]
    .flatMap((key) => data.stock_vs_index?.[key] || [])
    .sort((a, b) => {
      if (activeStockVsIndexSortType === "ticker") {
        return stockVsIndexTickerSortOrder === "asc"
          ? a.stock_symbol.localeCompare(b.stock_symbol)
          : b.stock_symbol.localeCompare(a.stock_symbol);
      } else if (activeStockVsIndexSortType === "stockPctChange") {
        return stockVsIndexStockPctSortOrder === "asc"
          ? a.Stock_pct_change - b.Stock_pct_change
          : b.Stock_pct_change - a.Stock_pct_change;
      } else if (activeStockVsIndexSortType === "indexPctChange") {
        return stockVsIndexIndexPctSortOrder === "asc"
          ? a.Index_pct_change - b.Index_pct_change
          : b.Index_pct_change - a.Index_pct_change;
      }
      return 0; // Default case if no active sort
    });

  // Handlers for toggling sorting
  const toggleStockVsIndexTickerSort = () => {
    setStockVsIndexTickerSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveStockVsIndexSortType("ticker");
  };

  const toggleStockVsIndexStockPctSort = () => {
    setStockVsIndexStockPctSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveStockVsIndexSortType("stockPctChange");
  };

  const toggleStockVsIndexIndexPctSort = () => {
    setStockVsIndexIndexPctSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveStockVsIndexSortType("indexPctChange");
  };

  const sortedClosePriceValues = [...combinedCloseValues].sort((a, b) => {
    if (activeCloseSortType === "ticker") {
      return closePriceSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeCloseSortType === "newValue") {
      return closeValueSortOrder === "asc"
        ? a.live_day_close - b.live_day_close
        : b.live_day_close - a.live_day_close;
    } else if (activeCloseSortType === "oldValue") {
      return oldCloseValueSortOrder === "asc"
        ? a.minmax - b.minmax
        : b.minmax - a.minmax;
    }
    return 0; // Default case
  });

  const toggleClosePriceSort = () => {
    setClosePriceSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveCloseSortType("ticker"); // Update active sort type
    setCloseValueSortOrder("asc"); // Reset new value sort
    setOldCloseValueSortOrder("asc"); // Reset old value sort
  };

  const toggleCloseValueSort = () => {
    setCloseValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveCloseSortType("newValue"); // Update active sort type
    setClosePriceSortOrder("asc"); // Reset ticker sort
    setOldCloseValueSortOrder("asc"); // Reset old value sort
  };

  const toggleOldCloseValueSort = () => {
    setOldCloseValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveCloseSortType("oldValue"); // Update active sort type
    setClosePriceSortOrder("asc"); // Reset ticker sort
    setCloseValueSortOrder("asc"); // Reset new value sort
  };

  const sortedOpenPriceValues = [...combinedOpenValues].sort((a, b) => {
    // Check which sort type is active and apply sorting based on it
    if (activeSortType === "ticker") {
      return openPriceSortOrder === "asc"
        ? a.ticker_symbol.localeCompare(b.ticker_symbol)
        : b.ticker_symbol.localeCompare(a.ticker_symbol);
    } else if (activeSortType === "newValue") {
      return openValueSortOrder === "asc"
        ? a.live_day_open - b.live_day_open
        : b.live_day_open - a.live_day_open;
    } else if (activeSortType === "oldValue") {
      return oldValueSortOrder === "asc"
        ? a.minmax - b.minmax
        : b.minmax - a.minmax;
    }
    return 0; // Default case
  });

  const toggleOpenPriceSort = () => {
    setOpenPriceSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveSortType("ticker");
    setOpenValueSortOrder("asc");
    setOldValueSortOrder("asc");
  };

  const toggleOpenValueSort = () => {
    setOpenValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setActiveSortType("newValue");
    setOpenPriceSortOrder("asc");
    setOldValueSortOrder("asc");
  };

  const toggleOldValueSort = () => {
    setOldValueSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setActiveSortType("oldValue");
    setOpenPriceSortOrder("asc");
    setOpenValueSortOrder("asc");
  };

  const sortedMomentumValues = [...combined_momentum_change].sort((a, b) => {
    if (momentumSortOrder === "asc") {
      return a.ticker_symbol.localeCompare(b.ticker_symbol);
    } else if (momentumSortOrder === "desc") {
      return b.ticker_symbol.localeCompare(a.ticker_symbol);
    }

    // Sorting by value (MFI)
    if (momentumValueSortOrder === "asc") {
      return a.MFI - b.MFI;
    } else if (momentumValueSortOrder === "desc") {
      return b.MFI - a.MFI;
    }

    return 0; // default
  });

  const toggleMomentumSort = () => {
    setMomentumSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const toggleMomentumValueSort = () => {
    setMomentumValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setMomentumSortOrder("asc"); // Reset ticker sort when value sort is toggled
  };

  const sortedPriceChangeValues = Object.keys(
    data.price_change_alerts.top_n_price_change.stock_symbol
  ).sort((a, b) => {
    if (priceChangeValueSortOrder === null) {
      if (priceChangeTickerSortOrder === "asc") {
        return a.localeCompare(b); // Ascending alphabetical order
      } else {
        return b.localeCompare(a); // Descending alphabetical order
      }
    } else {
      // If value sorting is active, we don't sort by ticker
      return 0;
    }
  });

  // Sort by value (% change) if value sorting is active
  if (priceChangeValueSortOrder !== null) {
    sortedPriceChangeValues.sort((a, b) => {
      const aValue =
        data.price_change_alerts.top_n_price_change.stock_symbol[a];
      const bValue =
        data.price_change_alerts.top_n_price_change.stock_symbol[b];

      if (priceChangeValueSortOrder === "asc") {
        return aValue - bValue; // Ascending by value
      } else {
        return bValue - aValue; // Descending by value
      }
    });
  }

  // Toggling functions
  const togglePriceChangeTickerSort = () => {
    setPriceChangeTickerSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setPriceChangeValueSortOrder(null); // Reset value sort when ticker sort is toggled
  };

  const togglePriceChangeValueSort = () => {
    setPriceChangeValueSortOrder((prevOrder) =>
      prevOrder === "asc" ? "desc" : "asc"
    );
    setPriceChangeTickerSortOrder("asc"); // Reset ticker sort when value sort is toggled
  };

  return (
    <>
      <Navbar />
      <section className="trending-section">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="link" onClick={toggleInfoBox}>
          What's this?
        </a>
        {showInfoBox && (
          <>
            <div id="infoBox" className="info-box">
              {/* Your info-box content here */}
              <div className="info-box-content">
                <p>{infoBoxText[highlightBox]}</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    width={18}
                    height={18}
                    onClick={() => setShowInfoBox(false)}
                  >
                    <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                  </svg>
                </div>
              </div>
              <div className="info-box-bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#fe5f55"
                  fillOpacity={0.8}
                  width={10}
                  height="1.5rem"
                  onClick={() =>
                    sethighlightBox((prev) => (prev - 1 < 0 ? 0 : prev - 1))
                  }
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                </svg>
                <span>
                  {highlightBox + 1}/{Object.keys(highlightBoxEnum).length}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#fe5f55"
                  fillOpacity={0.8}
                  width={10}
                  height="1.5rem"
                  onClick={() =>
                    sethighlightBox((prev) =>
                      prev + 1 > Object.keys(highlightBoxEnum).length
                        ? 0
                        : prev + 1
                    )
                  }
                >
                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                </svg>
              </div>
            </div>
            <div
              className="modal-backdrop"
              // onClick={() => setShowInfoBox(false)}
            ></div>
          </>
        )}
        <div className="grey-box">
          <div className="three-pane-split">
            <div className="price-pane">
              {/* Ticker filter Mobile view only */}
              <div className="only-mobile">
                <TickerFilter
                  selectedTicker={tickerFilter ?? undefined}
                  allTickers={tickerList}
                  onChange={(value) => {
                    setTickerFilter(value);
                  }}
                />
              </div>
              {/* stock-index card */}
              <PriceCard
                toggleInfoBox={toggleInfoBox}
                setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.OpenPrices)}
                highlightBox={
                  showInfoBox && highlightBox === highlightBoxEnum.OpenPrices
                }
                title={
                  <>
                    <span className="stock-vs-index-title">Stock vs Index</span>
                    <Tooltip
                      anchorSelect=".stock-vs-index-title"
                      place="top"
                      style={{
                        width: "40%",
                        fontSize: "0.8rem",
                        fontWeight: 400,
                      }}
                    >
                    Checks whether a sector’s index increases or decreases while the company’s stock 
                    belonging to the specific sector, increases or decreases. 
                    </Tooltip>
                  </>
                }
                headers={[
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={toggleStockVsIndexTickerSort}
                  >
                    Stock Ticker{" "}
                    {stockVsIndexTickerSortOrder === "asc" ? "↑" : "↓"}
                  </div>,
                  <div>Index</div>,
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={toggleStockVsIndexStockPctSort}
                  >
                    Stock change (%){" "}
                    {stockVsIndexStockPctSortOrder === "asc" ? "↑" : "↓"}
                  </div>,
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={toggleStockVsIndexIndexPctSort}
                  >
                    Index change (%){" "}
                    {stockVsIndexIndexPctSortOrder === "asc" ? "↑" : "↓"}
                  </div>,
                ]}
                values={sortedStockVsIndexData
                  .filter(
                    (key) => !tickerFilter || key.stock_symbol === tickerFilter
                  )
                  .map((value, i) => [
                    <div
                      className="ticker-name-trending"
                      onClick={() => {
                        const ticker = value.stock_symbol;
                        if (ticker) {
                          window.localStorage.setItem("selectedTab", "0");
                          window.localStorage.setItem("tickerId", ticker);
                          window.location.href = `/detail`;
                        }
                      }}
                    >
                      {value.stock_symbol}
                    </div>,
                    <div>{value.Index}</div>,
                    <div
                      className={
                        value.Stock_pct_change >= 0 ? "text-green" : "text-red"
                      }
                    >
                      {value.Stock_pct_change.toFixed(3)}%
                    </div>,
                    <div
                      className={
                        value.Index_pct_change >= 0 ? "text-green" : "text-red"
                      }
                    >
                      {value.Index_pct_change.toFixed(3)}%
                    </div>,
                  ])
                  .flat()}
              />

              <div className="outer-div-cont">
                {/* Price Change card */}
                <PriceCard
                  setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.PriceChange)}
                  toggleInfoBox={toggleInfoBox}
                  highlightBox={
                    showInfoBox && highlightBox === highlightBoxEnum.PriceChange
                      ? true
                      : false
                  }
                  title={
                    <>
                      <span className="price-change-title">Price Change</span>
                      <Tooltip
                        anchorSelect=".price-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Percent of change from previous day <br />
                        Sorted by percent change
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      onClick={togglePriceChangeTickerSort}
                      style={{ cursor: "pointer" }}
                    >
                      Stock Ticker{" "}
                      {priceChangeTickerSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={togglePriceChangeValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Value (% Change){" "}
                      {priceChangeValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                  ]}
                  values={sortedPriceChangeValues
                    .filter((key) => !tickerFilter || key === tickerFilter)
                    .map((key, i) => {
                      const isRed =
                        data.price_change_alerts.top_n_price_change
                          .stock_symbol[key] < 0;
                      return [
                        <div
                          className="ticker-name-trending"
                          onClick={() => {
                            const ticker = key;
                            if (ticker) {
                              window.localStorage.setItem("selectedTab", "0");
                              window.localStorage.setItem("tickerId", ticker);
                              window.location.href = `/detail`;
                            }
                          }}
                          key={`${i}-ticker`}
                        >
                          {key}
                        </div>,
                        <div className="int-value">
                          <div
                            className={
                              isRed
                                ? "text-red-price-change"
                                : "text-green-price-change"
                            }
                            key={`${i}-value`}
                          >
                            {data.price_change_alerts.top_n_price_change.stock_symbol[
                              key
                            ]
                              .toPrecision(3)
                              .toString()}
                          </div>
                        </div>,
                      ];
                    })
                    .flat()}
                />
                {/* Open Price Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.OpenPrices)}
                  highlightBox={
                    showInfoBox && highlightBox === highlightBoxEnum.OpenPrices
                      ? true
                      : false
                  }
                  title={
                    <>
                      <span className="open-price-title">Open Prices</span>
                      <Tooltip
                        anchorSelect=".open-price-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Identifies the highest and lowest open prices in the
                        last 15 days
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      onClick={toggleOpenPriceSort}
                      style={{ cursor: "pointer" }}
                    >
                      Stock Ticker {openPriceSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleOpenValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      New Value* {openValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleOldValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Old Value {oldValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                  ]}
                  values={sortedOpenPriceValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => {
                      const isRed = value.min_open !== undefined;
                      return [
                        <div
                          className="ticker-name-trending"
                          onClick={() => {
                            const ticker = value.ticker_symbol;
                            if (ticker) {
                              window.localStorage.setItem("selectedTab", "0");
                              window.localStorage.setItem("tickerId", ticker);
                              window.location.href = `/detail`;
                            }
                          }}
                        >
                          <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                        </div>,
                        <div className="int-value">
                          <div
                            className={isRed ? "text-red" : "text-green"}
                            key={`${i}-value`}
                          >
                            {value.live_day_open.toFixed(2).toString()}
                          </div>
                        </div>,
                        <div key={`${i}-minmax`}>
                          {value.minmax.toFixed(2).toString()}
                        </div>,
                      ];
                    })
                    .flat()}
                />

                {/* Close Prices Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(
                    highlightBoxEnum.LastTradedPrice
                  )}
                  highlightBox={
                    showInfoBox &&
                    highlightBox === highlightBoxEnum.LastTradedPrice
                      ? true
                      : false
                  }
                  title={
                    <>
                      <span className="close-price-title">Close Prices</span>
                      <Tooltip
                        anchorSelect=".close-price-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Identifies the highest and lowest close prices in the
                        last 15 days
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      onClick={toggleClosePriceSort}
                      style={{ cursor: "pointer" }}
                    >
                      Stock Ticker {closePriceSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleCloseValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      New Value* {closeValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleOldCloseValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Old Value {oldCloseValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                  ]}
                  values={sortedClosePriceValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => {
                      const isRed = value.min_close !== undefined;
                      return [
                        <div
                          className="ticker-name-trending"
                          onClick={() => {
                            const ticker = value.ticker_symbol;
                            if (ticker) {
                              window.localStorage.setItem("selectedTab", "0");
                              window.localStorage.setItem("tickerId", ticker);
                              window.location.href = `/detail`;
                            }
                          }}
                        >
                          <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                        </div>,
                        <div className="int-value">
                          <div
                            className={isRed ? "text-red" : "text-green"}
                            key={`${i}-value`}
                          >
                            {value.live_day_close.toFixed(2).toString()}
                          </div>
                        </div>,
                        <div key={`${i}-minmax`}>
                          {value.minmax.toString()}
                        </div>,
                      ];
                    })
                    .flat()}
                />
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.StockVolume)}
                  highlightBox={
                    showInfoBox && highlightBox === highlightBoxEnum.StockVolume
                  }
                  title={
                    <>
                      <span className="volume-change-title">Stock Volume</span>
                      <Tooltip
                        anchorSelect=".volume-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Triggers when current minute's volume exceeds or falls
                        below the 7-day high or low volume for that minute.
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      onClick={toggleVolumeSort}
                      style={{ cursor: "pointer" }}
                    >
                      Stock Ticker {volumeSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleVolumeValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Value* (lacs){volumeValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="volume-minmax-header">Alerts</div>,
                  ]}
                  values={sortedVolumeValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = `/detail`;
                          }
                        }}
                      >
                        <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value">
                        <div className={"text-yellow"} key={`${i}-value`}>
                          {/* convert value into crores */}
                          {(value["Current Volume"] / 100000).toFixed(2)}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.volume_type === "high_volume" ? "High" : "Low"}
                      </div>,
                    ])
                    .flat()}
                />

                {/* A/D Card */}
                <PriceCard
                  title={
                    <>
                      <span className="AD-title">A/D</span>
                      <Tooltip
                        anchorSelect=".AD-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        The accumulation/distribution indicator (A/D) is a cumulative indicator that uses volume and price to assess whether a stock is being accumulated or distributed. Triggers when current minute’s A/D value exceeds or falls below the 7-day high or low A/D value for that minute. Used to isolate short term cycles in price movements. 
                      </Tooltip>
                    </>
                  }
                  headers={[
                    // Clickable header for sorting by Stock Ticker
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleAdTickerSort}
                    >
                      Stock Ticker {adSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    // Clickable header for sorting by Value
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleAdValueSort}
                    >
                      Value* {adValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="volume-minmax-header">Alerts</div>,
                  ]}
                  values={sortedAD
                    .filter(
                      (value) =>
                        !tickerFilter || value.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker_symbol;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = `/detail`;
                          }
                        }}
                        key={`${i}-ticker`}
                      >
                        <div>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value" key={`${i}-value`}>
                        <div
                          className={
                            value.AD_TYPE !== "accumulation"
                              ? "text-red"
                              : "text-green"
                          }
                        >
                          {value["Current A/D"]
                            ? value["Current A/D"].toFixed(2)
                            : "N/A"}{" "}
                          {/* Fallback for undefined */}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.AD_TYPE === "accumulation"
                          ? "Accumulation"
                          : "Distribution"}
                      </div>,
                    ])
                    .flat()}
                />
                {/* DPO Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.StockVolume)}
                  highlightBox={
                    showInfoBox && highlightBox === highlightBoxEnum.StockVolume
                  }
                  title={
                    <>
                      <span className="volume-change-title">DPO</span>
                      <Tooltip
                        anchorSelect=".volume-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        A detrended price oscillator, used in technical analysis, strips out price trends in an effort to estimate the length of price cycles from peak to peak or trough to trough. Generates an alert if the current DPO value exceeds or precedes the custom defined  threshold .
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleDpoTickerSort}
                    >
                      Stock Ticker {dpoSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={toggleDpoValueSort}
                    >
                      Value* {dpoValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="volume-minmax-header">Alerts</div>,
                  ]}
                  values={sortedDPO
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker_symbol;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = `/detail`;
                          }
                        }}
                      >
                        <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value" key={`${i}-value`}>
                        <div
                          className={
                            value.DPO_TYPE !== "up" ? "text-red" : "text-green"
                          }
                        >
                          {value.Current_dpo?.toFixed(2)}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.DPO_TYPE === "up" ? "Upward" : "Downward"}
                      </div>,
                    ])
                    .flat()}
                />
                {/* ATR Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(
                    highlightBoxEnum.AverageTrueRange
                  )}
                  highlightBox={
                    showInfoBox &&
                    highlightBox === highlightBoxEnum.AverageTrueRange
                  }
                  title={
                    <>
                      <span className="atr-change-title">ATR</span>
                      <Tooltip
                        anchorSelect=".atr-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        ATR (Average True Range) measures volatility. If the
                        current ATR exceeds the 14-day max, a high volatility
                        alert is triggered; if below the 14-day min, a low
                        volatility alert is generated.
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div onClick={toggleAtrSort} style={{ cursor: "pointer" }}>
                      Stock Ticker {atrSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleAtrValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Value* {atrValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="atr-price-minmax-header">Volatility</div>,
                  ]}
                  values={sortedAtrValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker_symbol;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = "/detail";
                          }
                        }}
                      >
                        <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value">
                        <div
                          className={
                            value.volatilityType !== "high_volatility"
                              ? "text-yellow"
                              : "text-yellow"
                          }
                          key={`${i}-value`}
                        >
                          {value.Last_ATR.toFixed(2)}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.volatilityType === "high_volatility"
                          ? "High"
                          : "Low"}
                      </div>,
                    ])
                    .flat()}
                />
                {/* ADX Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(
                    highlightBoxEnum.AverageDirectionalIndex
                  )}
                  highlightBox={
                    showInfoBox &&
                    highlightBox === highlightBoxEnum.AverageDirectionalIndex
                  }
                  title={
                    <>
                      <span className="adx-change-title">ADX</span>
                      <Tooltip
                        anchorSelect=".adx-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        ADX (Average Directional Index) measures trend strength.
                        If ADX exceeds 40, it indicates a strong trend; below
                        15, it signals a weak trend, based on a 14-day
                        comparison.
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div onClick={toggleAdxSort} style={{ cursor: "pointer" }}>
                      Stock Ticker {adxSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleAdxValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Value* {adxValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="adx-price-minmax-header">Trend</div>,
                  ]}
                  values={sortedAdxValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker_symbol;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = `/detail`;
                          }
                        }}
                      >
                        <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value">
                        <div
                          className={
                            value.trendType !== "strong trend"
                              ? "text-red"
                              : "text-green"
                          }
                          key={`${i}-value`}
                        >
                          {value.ADX.toFixed(2)}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.trendType === "strong trend" ? "Strong" : "Weak"}
                      </div>,
                    ])
                    .flat()}
                />
                {/* VWAP Card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(
                    highlightBoxEnum.VolumeWeightedAveragePrice
                  )}
                  highlightBox={
                    showInfoBox &&
                    highlightBox === highlightBoxEnum.VolumeWeightedAveragePrice
                  }
                  title={
                    <>
                      <span className="vwap-change-title">VWAP</span>
                      <Tooltip
                        anchorSelect=".vwap-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Signals an uptrend if the current VWAP surpasses the
                        14-day max, and a downtrend if below the 14-day min.
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div onClick={toggleVwapSort} style={{ cursor: "pointer" }}>
                      Stock Ticker {vwapSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div
                      onClick={toggleVwapValueSort}
                      style={{ cursor: "pointer" }}
                    >
                      Value* {vwapValueSortOrder === "asc" ? "↑" : "↓"}
                    </div>,
                    <div className="vwap-minmax-header">Trend</div>,
                  ]}
                  values={sortedVwapValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => [
                      <div
                        className="ticker-name-trending"
                        onClick={() => {
                          const ticker = value.ticker_symbol;
                          if (ticker) {
                            window.localStorage.setItem("selectedTab", "0");
                            window.localStorage.setItem("tickerId", ticker);
                            window.location.href = `/detail`;
                          }
                        }}
                      >
                        <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                      </div>,
                      <div className="int-value">
                        <div
                          className={
                            value.trend_type !== "uptrend"
                              ? "text-red"
                              : "text-green"
                          }
                          key={`${i}-value`}
                        >
                          {value.current_vwap}
                        </div>
                      </div>,
                      <div key={`${i}-minmax`}>
                        {value.trend_type === "uptrend" ? "Up" : "Down"}
                      </div>,
                    ])
                    .flat()}
                />
                {/* Momentum Change card */}
                <PriceCard
                  toggleInfoBox={toggleInfoBox}
                  setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.MomentumChange)}
                  highlightBox={
                    showInfoBox &&
                    highlightBox === highlightBoxEnum.MomentumChange
                      ? true
                      : false
                  }
                  title={
                    <>
                      <span className="momentum-change-title">Momentum</span>
                      <Tooltip
                        anchorSelect=".momentum-change-title"
                        place="top"
                        style={{
                          width: "80%",
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        Generated when RSI, MFI, and Williams %R simultaneously
                        signal oversold or overbought conditions.
                      </Tooltip>
                    </>
                  }
                  headers={[
                    <div
                      onClick={toggleMomentumSort}
                      style={{ cursor: "pointer" }}
                    >
                      Stock Ticker {momentumSortOrder === "asc" ? "↑" : "↓"}
                    </div>,

                    <div className="momentum-price-minmax-header">Alerts</div>,
                  ]}
                  values={sortedMomentumValues
                    .filter(
                      (key) =>
                        !tickerFilter || key.ticker_symbol === tickerFilter
                    )
                    .map((value, i) => {
                      const isGreen = value.bought_type === "overbought";
                      return [
                        <div
                          className="ticker-name-trending"
                          onClick={() => {
                            const ticker = value.ticker_symbol;
                            if (ticker) {
                              window.localStorage.setItem("selectedTab", "0");
                              window.localStorage.setItem("tickerId", ticker);
                              window.location.href = `/detail`;
                            }
                          }}
                        >
                          <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                        </div>,

                        <div className="text-yellow" key={`${i}-minmax`}>
                          {value.Alert}
                        </div>,
                      ];
                    })
                    .flat()}
                />
              </div>
            </div>

            {/* column3 starts */}
            <div className="trending-wrapper">
              <div className="news-stories-pane">
                {/* Ticker filter Desktop view */}
                <div
                  className="only-desktop"
                  style={
                    highlightBox === highlightBoxEnum.FilterTicker
                      ? { zIndex: 20 }
                      : {}
                  }
                  ref={(ref) =>
                    (infoBoxRefList.current[highlightBoxEnum.FilterTicker] =
                      ref)
                  }
                >
                  <TickerFilter
                    selectedTicker={tickerFilter ?? undefined}
                    allTickers={tickerList}
                    onChange={(value) => {
                      setTickerFilter(value);
                    }}
                  />
                </div>

                {/* news count ends */}
              </div>
              {/* Hot Events Card */}
              <div
                className="events-pane container-events"
                style={
                  highlightBox === highlightBoxEnum.HotEvents
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.HotEvents] = ref)
                }
              >
                <>
                  <h3 className="heading">Hot Events</h3>
                  <Tooltip anchorSelect=".hot-event-title" place="top">
                    Identifies top news events and gathers related news data to
                    provide a summary for each company's most important event.
                  </Tooltip>
                </>

                <div className="events-position">
                  <div className="events-flex">
                    {(() => {
                      const filteredEvents = Object.keys(
                        data.events_with_summary
                      )
                        // Filter by ticker
                        .filter((key) => {
                          if (!tickerFilter) return true;
                          return (
                            data.events_with_summary[key].stock_symbol ===
                            tickerFilter
                          );
                        });

                      return filteredEvents.length === 0 ? (
                        <div>
                          <div className="no-data">No data available</div>
                        </div>
                      ) : (
                        filteredEvents.map((key, i) => (
                          <TrendingEventCard
                            key={i}
                            title={`${key}`}
                            ticker={data.events_with_summary[key].stock_symbol}
                            events={[
                              {
                                title: data.events_with_summary[key].max_event,
                                subtitle: data.events_with_summary[key].summary,
                              },
                            ]}
                          />
                        ))
                      );
                    })()}
                  </div>
                </div>
              </div>

              {/* new qoutes section start*/}
              <div
                className="events-pane container-events"
                style={
                  highlightBox === highlightBoxEnum.Quotes ? { zIndex: 20 } : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.Quotes] = ref)
                }
              >
                <>
                  <h3 className="heading">Quotes</h3>
                  <Tooltip anchorSelect=".hot-event-title" place="top">
                    Identifies top Quotes and gathers related data to provide a
                    summary and context for each company/person.
                  </Tooltip>
                </>
                <div className="events-position">
                  <div className="events-flex">
                    {(() => {
                      const filteredQuotes = quotes
                        .filter((quote) => {
                          if (!tickerFilter) return true;
                          return quote.ticker_symbol === tickerFilter;
                        })
                        .filter(
                          (quote) =>
                            quote.quote !== "0" && quote.context !== "0"
                        ); // Filter out quotes and contexts with value 0

                      return filteredQuotes.length === 0 ? (
                        <div>
                          <div className="no-data">No data available</div>
                        </div>
                      ) : (
                        filteredQuotes.map((quote, i) => (
                          <TrendingEventCard
                            key={i}
                            title={quote.company_name}
                            ticker={quote.ticker_symbol}
                            events={[
                              {
                                title: (
                                  <a
                                    href={quote.link}
                                    target="_blank"
                                    className="quotes-link"
                                    rel="noopener noreferrer"
                                  >
                                    "{quote.quote}"
                                  </a>
                                ),
                                subtitle: quote.context,
                              },
                            ]}
                          >
                            {/* Display person's name at the bottom */}
                            <div
                              className="person-name"
                              style={{ marginTop: "10px", fontStyle: "italic" }}
                            >
                              - {quote.Person}
                            </div>
                          </TrendingEventCard>
                        ))
                      );
                    })()}
                  </div>
                </div>
              </div>
              {/* Top Stories Card */}
              <div className="story-news-combo">
                <div
                  className="top-stories-card"
                  style={
                    highlightBox === highlightBoxEnum.NewsStories
                      ? { zIndex: 20 }
                      : {}
                  }
                  ref={(ref) =>
                    (infoBoxRefList.current[highlightBoxEnum.NewsStories] = ref)
                  }
                >
                  <>
                    <h3 className="title top-stories-title">Top Stories</h3>
                    <Tooltip anchorSelect=".top-stories-title" place="top">
                      "Top Stories" features news with the corresponding company
                      mentioned over five times.
                    </Tooltip>
                  </>
                  <div className="stories-list">
                    {(() => {
                      const filteredStories = Object.keys(
                        data.Top_stories_using_TSA_count
                      )
                        // Filter by ticker
                        .filter((key) => {
                          if (!tickerFilter) return true;
                          return (
                            data.Top_stories_using_TSA_count[key]
                              .stock_symbol === tickerFilter
                          );
                        });

                      return filteredStories.length === 0 ? (
                        <div className="no-data"> NO STORY !</div>
                      ) : (
                        filteredStories.map((key, i) => (
                          <a
                            className="story"
                            key={i}
                            href={data.Top_stories_using_TSA_count[key].link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h4 className="date-time">
                              {data.Top_stories_using_TSA_count[key].news_date}
                            </h4>
                            <div className="story-title">
                              {data.Top_stories_using_TSA_count[key].title}
                            </div>
                          </a>
                        ))
                      );
                    })()}
                  </div>
                </div>
                {/* News Count card starts*/}
                <div
                  className="news-count-card"
                  style={
                    highlightBox === highlightBoxEnum.NewsCount
                      ? { zIndex: 20 }
                      : {}
                  }
                  ref={(ref) =>
                    (infoBoxRefList.current[highlightBoxEnum.NewsCount] = ref)
                  }
                >
                  <>
                    <h3 className="title news-count-title">News Count</h3>
                    <Tooltip anchorSelect=".news-count-title" place="top">
                      Identifies the number of news articles published in the
                      last 24 hours and the weekly average.
                    </Tooltip>
                  </>

                  <div className="news-list">
                    {data?.news_count?.all_companies ? (
                      (() => {
                        const filteredNews = Object.keys(
                          data.news_count.all_companies
                        )
                          ?.sort()
                          // Filter by ticker
                          .filter((key) => {
                            if (!tickerFilter) return true;
                            return (
                              data.news_count.all_companies[key]
                                .stock_symbol === tickerFilter
                            );
                          });

                        return filteredNews.length === 0 ? (
                          <div className="no-data"> NO DATA !</div>
                        ) : (
                          filteredNews.map((key, i) => (
                            <div className="news" key={i}>
                              <Link
                                to={`/news/${
                                  data.news_count.all_companies[key]
                                    .stock_symbol || key
                                }`}
                              >
                                <h4 className="ticker">
                                  {data.news_count.all_companies[key]
                                    .stock_symbol || key}
                                </h4>
                              </Link>
                              <div className="count-details">
                                News :
                                <span
                                  style={{ color: "green", fontWeight: 700 }}
                                >
                                  {
                                    data.news_count.all_companies[key]
                                      .news_count
                                  }{" "}
                                </span>
                                | Weekly Avg :{" "}
                                {
                                  data.news_count.all_companies[key]
                                    .avg_7days_news
                                }
                              </div>
                            </div>
                          ))
                        );
                      })()
                    ) : (
                      <div className="no-data">No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* last column complete starts */}
          </div>
        </div>
        {/* Footer disclaimer */}
        <div className="footer-disclaimer">
          <div className="disclaimer">
            *values are the highest/lowest values in last 15 days. In New Value
            column, “<span style={{ color: "red" }}>Red</span>” color signifies
            lowest where as “<span style={{ color: "green" }}>Green</span>”
            stands for highest
          </div>
          {/* updated */}
          <div className="updated">Updated on : {data.date}</div>
        </div>
      </section>

      <MiniFooter />
    </>
  );
};

// const RedDownArrow = () => (
//   <svg
//     style={{
//       height: "1rem",
//       aspectRatio: "1/1",
//       marginLeft: "0.5rem",
//     }}
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 15 18"
//     fill="#ED0000"
//   >
//     <path
//       d="M7.57637 17.2019L0.583213 0.417997L14.5695 0.417996L7.57637 17.2019Z"
//       fill="#ED0000"
//     />
//   </svg>
// );

// const GreenUpArrow = () => (
//   <svg
//     style={{
//       height: "1rem",
//       aspectRatio: "1/1",
//       marginLeft: "0.5rem",
//     }}
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 15 18"
//     fill="#2C8745"
//   >
//     <path
//       d="M7.57598 0.908096L14.5691 17.692L0.582821 17.692L7.57598 0.908096Z"
//       fill="#2C8745"
//     />
//   </svg>
// );

export default Trending;
