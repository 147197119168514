import moment from "moment";
import { Tooltip } from "antd";
import { FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import Grid from "@mui/material/Grid";
// import Tooltip from '@mui/material/Tooltip';
import { useTheme, useMediaQuery } from "@mui/material";
// import Hidden from "@mui/material/Hidden";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useDispatch, useSelector } from "react-redux";

import { summary } from "../../../Actions/summary";

// import information from "../../../assets/images/icons/infoIcon.png";
// import probLegendIcon from "../../../assets/images/icons/sticky-notes.png";
// import ProbScoreLegend from "../ProbabilityScoreLegend/ProbScoreLegend";
import useWindowDimemsions from "../../../hooks/useWindowDimesnions";
import SearchBar from "../../Common/SearchBar/SearchBar";
import Marquee from "./Marquee";

import "./styles/DashboardHeader.scss";
// import { Height, Padding } from "@mui/icons-material";
// import styles from './DashboardHeader.module.css'; // Importing the CSS module

const toolTipTitle = (
  <div>
    <div style={{ marginBottom: "8px" }}>
      The background color of the boxes indicates the percentile ranking of a
      value over the past 30 days
    </div>
    <div className="table-legend-only">
      <div className="table-legend-item">
        <i className="fas fa-stop pe-2" style={{ color: "#84FED2" }}></i>
        <span className="pe-2">Top&nbsp;75&nbsp;-&nbsp;100%</span>
      </div>

      <div className="table-legend-item">
        <i className="fas fa-stop pe-2" style={{ color: "#FFE7A8" }}></i>
        <span className="pe-2">Middle&nbsp;25&nbsp;-&nbsp;75%</span>
      </div>

      <div className="table-legend-item">
        <i className="fas fa-stop pe-2" style={{ color: "#FFC7D4" }}></i>
        <span className="pe-2">Bottom&nbsp;0&nbsp;-&nbsp;25%</span>
      </div>
    </div>
  </div>
);
/**
 * The `DashboardHeader` component displays the header section of the dashboard page.
 * It includes information about the last update dates, a search bar, and legends for data visualization.
 *
 * This function is called in src\Component\Dashboard\Dashboard.jsx
 *
 * @param {Date} updateDate1 - The date when news data was last updated.
 * @param {Date} updateDate2 - The date when stock data was last updated.
 * @param {string} searchFilter - The current search filter value.
 * @param {Array} optionsData - The data used for the search bar options.
 * @param {Function} onSearchChange - The callback function to handle search bar value changes.
 * @param {boolean} displayProbScore - Indicates whether to display the probability score legend.
 * @returns {JSX.Element} The rendered `DashboardHeader` component.
 */
const DashboardHeader = ({
  updateDate1,
  updateDate2,
  searchFilter,
  optionsData,
  onSearchChange,
  displayProbScore,
  showOnlyNifty50,
  setShowOnlyNifty50,
}) => {
  const { width } = useWindowDimemsions();
  const lgWidth = 991;

  const removeSpace = (item) => {
    let str = item;

    if (str.indexOf(" ") >= 0) {
      str = str.split(" ").join("\u00A0");
    }

    return str;
  };

  // Header states migrated
  const headerFilter = useSelector((state) => state.FilterSummary.filter);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(summary(event.target.value));
    //setAge(event.target.value);
  };

  // Header style components
  const styleObj = width && width > lgWidth ? {} : { fontSize: "small" };
  const listItemStyle =
    width && width > lgWidth
      ? {}
      : { fontSize: "small", minHeight: "2px !important" };

  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  
 
  
  const selectStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      height: '2.5rem', // Adjust height as needed
      
    },
    '& .MuiOutlinedInput-input': {
      // padding: '200px', // Adjust padding to ensure text is centered vertically
    },
  };

  return (
    <>
      

      <Marquee
        text1={
          "     News Updated on    " +
          removeSpace(moment(updateDate1).format("DD-MMM-YYYY HH:mm "))
        }
        text2={
          "     Stocks Updated on    " +
          removeSpace(moment(updateDate2).format(" DD-MMM-YYYY HH:mm "))
        }
      />
<Grid style = {{display :"flex", marginBottom :"2rem"}}>

      <Grid className="outer-grid" container spacing={2} style={{gap :"2rem", marginTop: "6px",display: 'flex', flexWrap: 'nowrap', width :"85%" }}>
        
        
        <Grid style={{flex :"1", alignContent :"center"}}>
          <SearchBar
            value={searchFilter}
            data={optionsData}
            onChange={onSearchChange}
            placeholder={"Search by Ticker"}
            allowClearSearch={true}
            styled={{fontWeight: "bold",}}
            selectStyled={{
              top:"5px",
              border: "1px solid #D3D3D3",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "2.5rem",
              borderRadius: "10px",
            }}
          />
        </Grid>
{/* 
        <Grid
      container
      spacing={2}
      direction="row"
      style={{ flex :"1", marginTop: '0.2rem',marginBottom: '2.5rem', display: 'flex', flexWrap: 'nowrap' }}
    > */}
<div className="filter-sort-combine">
      <Grid className="inner-grid" item xs={12} md={6} style={{ flex: 1, alignContent :"center" }}>
      <FormControl fullWidth variant="outlined" sx={selectStyles}>
          <InputLabel id="select-label-1" style = {{top : "-6px", fontSize : "14px", fontWeight :"bold"}}>Filter By</InputLabel>
          <Select
          className = "filterby"
          // style = {{top: "5px"}}
            labelId="select-label-1"
            label="Filter By"
            onChange={(event) => {
              // console.log(event.target.value);
              setShowOnlyNifty50(event.target.value);
            }}
          
          >
            {[
                    { value: "all", label: "All" },
                    { value: "nifty", label: "Nifty50" },
                    { value: "nonnifty", label: "Non-Nifty50" },
                  ].map((item) => (
                    <MenuItem
                      key={item.label}
                      value={item.value}
                      sx={listItemStyle}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid  className="inner-grid2" item xs={12} md={6} style={{ flex: 1, alignContent :"center" }}>
        <FormControl fullWidth variant="outlined" sx={selectStyles}>
          <InputLabel id="select-label-2" style = {{top : "-6px" ,fontSize : "14px", fontWeight :"bold"}} >Sort By</InputLabel>
          <Select className = "sort-by"  labelId="select-label-2" label="Select 2" onChange={handleChange} MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300, // Set the height limit for the dropdown menu
          },
        },
      }}>
          <MenuItem value="All" sx={listItemStyle}>
                  All Sectors
                </MenuItem>
                {/* <MenuItem value="BANK">Banking</MenuItem> */}
                <MenuItem value="AUTO ANCILLARIES" sx={listItemStyle}>
                  Auto Ancillaries
                </MenuItem>
                <MenuItem value="AUTOMOBILE" sx={listItemStyle}>
                  Automobile
                </MenuItem>
                <MenuItem value="BEARINGS" sx={listItemStyle}>
                  Bearings
                </MenuItem>
                <MenuItem
                  value="CAPITAL GOODS-NON ELECTRICAL EQUIPMENT"
                  sx={listItemStyle}
                >
                  Capital Goods-Non Electrical Equipment
                </MenuItem>
                <MenuItem value="CHEMICALS" sx={listItemStyle}>
                  Chemicals
                </MenuItem>
                <MenuItem value="CONGLOMERATE" sx={listItemStyle}>
                  Conglomerate
                </MenuItem>
                <MenuItem value="CONSTRUCTION" sx={listItemStyle}>
                  Construction
                </MenuItem>
                <MenuItem value="CONSTRUCTION MATERIALS" sx={listItemStyle}>
                  Construction Materials
                </MenuItem>
                <MenuItem value="CONSUMER DURABLES" sx={listItemStyle}>
                  Consumer Durables
                </MenuItem>
                <MenuItem value="FINANCIAL SERVICES" sx={listItemStyle}>
                  Financial Services
                </MenuItem>
                <MenuItem value="FAST MOVING CONSUMER GOODS" sx={listItemStyle}>
                  FMCG
                </MenuItem>
                <MenuItem value="HEALTHCARE" sx={listItemStyle}>
                  Healthcare
                </MenuItem>
                <MenuItem value="IT" sx={listItemStyle}>
                  IT
                </MenuItem>
                <MenuItem value="MISCELLANEOUS" sx={listItemStyle}>
                  Miscellaneous
                </MenuItem>
                <MenuItem value="METALS AND MINING" sx={listItemStyle}>
                  Metals and Mining
                </MenuItem>
                <MenuItem
                  value="OIL GAS AND CONSUMABLE FUEL"
                  sx={listItemStyle}
                >
                  Oil Gas and Consumable Fuel
                </MenuItem>
                <MenuItem value="POWER" sx={listItemStyle}>
                  Power
                </MenuItem>
                <MenuItem value="SERVICES" sx={listItemStyle}>
                  Services
                </MenuItem>
                <MenuItem value="TELECOMMUNICATION" sx={listItemStyle}>
                  Telecommunication
                </MenuItem>
                {headerFilter === "" && (
                  <MenuItem disabled value="" sx={listItemStyle}>
                    <span
                      style={{
                        opacity: "0.5",
                        fontWeight: "500",
                        color: "gray",
                      }}
                    >
                      Select Sector
                    </span>
                  </MenuItem>
                )}
          </Select>
        </FormControl>
      </Grid>   
      </div>  
          
      </Grid>
      {/* <Grid item md={3} container justifyContent="flex-start" style={{flex :"1", alignContent :"center"}}> */}
            <Tooltip
              title={toolTipTitle}
              placement={width && width > 1264 ? "bottom" : "left"}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} // Partially transparent black color
            >
              <HelpOutlineIcon
              className="tooltip-icon"
              />
            </Tooltip>
          {/* </Grid> */}


          </Grid>


      {/* filterby- sortby */}

      
{/*         <div className="flex-item-two">
          {displayProbScore === true && (
            <div className="probability-score-legend-image">
              <Popover
                // placement= "left"
                showArrow={false} //experimental
                //trigger="click"
                content={<ProbScoreLegend />}
              >
                <img //searched important icon
                  src={probLegendIcon}
                  alt="Probability score tooltip"
                />
              </Popover>
            </div>
          )}
        </div> */}
      {/* </div> */}
    </>
  );
};

export default DashboardHeader;
